import { UPLOAD_SKRIPT, MAX_IMAGE_WIDTH, IS_DEBUGGING } from "./config";

export const HASHTAG_REGEX = /#\w+/g;

export const createId = (addOn = "") => {
  return `${createUUIDv4()}${addOn}`;
};

export const createUUIDv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const uploadFiles = data => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", UPLOAD_SKRIPT, true);

    console.log(data);

    xhr.upload.onprogress = e => {
      if (e.lengthComputable) {
        const percentComplete = (e.loaded / e.total) * 100;
        if (IS_DEBUGGING) console.log(percentComplete + "% uploaded");
      }
    };
    xhr.onload = () => {
      if (xhr.status === 200) {
        if (IS_DEBUGGING) console.log("Server sent:", xhr.response);
        try {
          resolve(JSON.parse(xhr.response));
        } catch {
          reject({
            status: xhr.status,
            statusText: xhr.statusText,
            response: xhr.response
          });
        }
      } else {
        reject({ status: xhr.status, statusText: xhr.statusText });
      }
    };
    xhr.onerror = () => {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };

    xhr.send(data);
  });
};

export const getTagsFromText = text => {
  const regex = HASHTAG_REGEX;
  const tags = text.match(regex) || [];
  const lowerCaseTags = tags.map(string => string.toLowerCase());

  return lowerCaseTags;
};

export const handleFiles = (files, fileName) => {
  const maxWidth = MAX_IMAGE_WIDTH;

  const filesPromises = Array.from(files).map((file, index) => {
    const reader = new FileReader();
    const image = new Image();

    return new Promise((successCallback, errorCallback) => {
      if (!isVideo(file.type) && !isImage(file.type)) {
        errorCallback(new Error("Not an image"));
        return;
      }

      if (file.type.match(/image.*/)) {
        reader.onload = readerEvent => {
          image.onload = () => {
            successCallback({
              blob: resizeImage(image, maxWidth),
              key: index,
              fileName: `${fileName}.jpg`
            });
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }

      if (file.type.match(/video*/)) {
        reader.onload = readerEvent => {
          successCallback({
            blob: new Blob([file]),
            key: index,
            fileName: `${fileName}.mp4`
          });
        };
        reader.readAsDataURL(file);
      }
    });
  });

  return filesPromises;
};

export const resizeImage = (image, maxWidth) => {
  const canvas = document.createElement("canvas");
  let width = image.width;
  let height = image.height;

  if (width > maxWidth) {
    height *= maxWidth / width;
    width = maxWidth;
  }

  canvas.width = width;
  canvas.height = height;
  canvas.getContext("2d").drawImage(image, 0, 0, width, height);

  const dataUrl = canvas.toDataURL("image/jpeg");

  return dataUriToBlob(dataUrl);
};

export const dataUriToBlob = dataUri => {
  const bytes =
    dataUri.split(",")[0].indexOf("base64") >= 0
      ? atob(dataUri.split(",")[1])
      : unescape(dataUri.split(",")[1]);
  const mime = dataUri
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);

  for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);

  return new Blob([ia], { type: mime });
};

export const transformPostForStore = post => {
  return {
    ...post,
    imageSrc: post.imageSrc.split(","),
    tags: post.tags.split(",")
  };
};

export const transformPostsForStore = posts => {
  return posts.map(transformPostForStore);
};

export const transformPostForDatabase = postObject => {
  const newPost = { ...postObject };
  if (postObject.imageSrc) {
    newPost.imageSrc = postObject.imageSrc.join(",");
  }
  newPost.tags = postObject.tags.join(",");

  return newPost;
};

export const isVideo = type => {
  return type.match(/video*/);
};

export const isImage = type => {
  return type.match(/image.*/);
};
