import React, { Component } from 'react';

/* components */
import Header from './Header';

import Authenticate from './Authenticate';
import AuthenticatedApp from './AuthenticatedApp';

/* styles */
import '../styles/styles.scss';

class App extends Component {
  render() {
    let isAuth = this.props.auth;

    return (
      <div className="bnzstgrm">
        <Header />

        {isAuth === 'VALID' ?
          <AuthenticatedApp {...this.props} /> :
          <Authenticate {...this.props} />
        }
      </div>
    );
  }
}

export default App;
