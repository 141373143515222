import React, { Component } from 'react';
import classNames from 'classnames';

class Authenticate extends Component {
    authenticate = event => {
        event.preventDefault();

        this.props.setAuth(this.refs.password.value);
    }
    render() {
        const classes = classNames(['authForm', {
            'authForm--error': this.props.auth === 'INVALID'
        }]);
        return (
            <div className="authenticate">
                <h2>Anmeldung</h2>
                <form onSubmit={this.authenticate} className={classes}>
                    <input type="password" ref="password" placeholder="Passwort" required />
                    <button type="submit">Anmelden</button>
                </form>
            </div>
        )
    }
}

export default Authenticate;