import React, { Component } from 'react';
import Photo from './Photo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';

class SinglePost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditable: false
    };
  }

  getCurrentPostFromPosts = (posts, postId) => {
    const post = posts.filter(post => post.postId === postId);
    return post[0];
  };

  editPost = () => {
    const isEditable = true;

    this.setState({ isEditable });
  };

  cancelEdit = () => {
    const isEditable = false;

    this.setState({ isEditable });
  };

  removePost = () => {
    if (window.confirm('Diesen Eintrag wirklich löschen?')) {
      this.props.removePost(this.props.match.params.postId, this.props.history);
    }
  };

  render() {
    const { posts, match } = this.props;
    const currentPost = this.getCurrentPostFromPosts(
      posts,
      match.params.postId
    );

    return (
      <div className="singlePost">
        <Photo
          post={currentPost}
          isEditable={this.state.isEditable}
          cancelEdit={this.cancelEdit}
          {...this.props}
        />

        <span
          onClick={this.state.isEditable ? this.cancelEdit : this.editPost}
          className="editPost"
        >
          <FontAwesomeIcon
            icon={this.state.isEditable ? faTimes : faEdit}
            size="lg"
            fixedWidth={true}
          />
        </span>
        <span onClick={this.removePost} className="removePost">
          <FontAwesomeIcon icon={faTrash} size="lg" />
        </span>
      </div>
    );
  }
}

export default SinglePost;
