import React, { Component } from "react";
import { Pagination } from "./Pagination/Pagination";
import Photo from "./Photo";
import { TagCloud } from "./TagCloud/TagCloud";

class PhotoFeed extends Component {
  render() {
    const tag =
      this.props.match.params.tag && this.props.match.params.tag.toLowerCase();
    const searchTerm =
      this.props.match.params.searchterm &&
      this.props.match.params.searchterm.toLowerCase();
    const { posts } = this.props;
    const { application } = this.props;

    // sort by date
    const sortedPosts = posts.sort((a, b) => {
      if (a.date > b.date) {
        return -1;
      }
      if (a.date < b.date) {
        return 1;
      }
      return 0;
    });

    const filteredPosts = sortedPosts
      .filter(post => !tag || post.tags.indexOf(`#${tag}`) !== -1)
      .filter(
        post =>
          !searchTerm ||
          post.headline.toLowerCase().indexOf(searchTerm) !== -1 ||
            post.text.toLowerCase().indexOf(searchTerm) !== -1
      );

    const shownPosts = filteredPosts.filter((post, index) => {
      const lower = (application.currentPage - 1) * application.perPage;
      const upper = application.currentPage * application.perPage - 1;

      return index >= lower && index <= upper;
    });

    return (
      <div className="photoFeed">
        {shownPosts.map((post, index) => (
          <div className="post" key={index}>
            <Photo post={post} {...this.props} />
          </div>
        ))}
        {shownPosts.length === 0 && (
          <div className="no-posts">
            <h3>Keine Posts gefunden</h3>
            <TagCloud />
          </div>
        )}
        <Pagination total={filteredPosts.length} />
      </div>
    );
  }
}

export default PhotoFeed;
