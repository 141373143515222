import { SET_AUTH } from "../actions/actionTypes";
import { IS_DEBUGGING } from "../config";

export const authReducer = (state = "NOT_SET", action) => {
  // export const authReducer = (state = "VALID", action) => {
  switch (action.type) {
    case SET_AUTH:
      if (IS_DEBUGGING) console.log("setAuth", action.auth);
      return action.auth;
    default:
      return state;
  }
};
