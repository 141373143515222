import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPlusCircle,
  faSearch
} from "@fortawesome/free-solid-svg-icons";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <Link to="/">
          <FontAwesomeIcon icon={faHome} size="lg" />
        </Link>

        <Link to="/search">
          <FontAwesomeIcon icon={faSearch} size="lg" />
        </Link>

        <Link to="/new" className="addNewPost">
          <FontAwesomeIcon icon={faPlusCircle} size="3x" />
        </Link>
      </div>
    );
  }
}

export default Footer;
