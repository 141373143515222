import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

class Loading extends Component {
  render() {
    return (
      <div className="loading">
        <div className="loading__background" />
        <div className="loading__spinner">
          <FontAwesomeIcon icon={faCog} spin size="3x" />
        </div>
      </div>
    );
  }
}

export default Loading;
