import React, { Component } from "react";

/* components */
import PhotoFeed from "./PhotoFeed";
import SinglePost from "./SinglePost";
import NewPost from "./NewPost";
import Loading from "./Loading";
import { Search } from "./Search/Search";

/* router */
import { Route, Switch } from "react-router-dom";

class Router extends Component {
  componentWillMount() {
    this.props.getPosts();
  }

  render() {
    return (
      <Switch>
        <Route
          path="/"
          exact
          render={routeProps => <PhotoFeed {...this.props} {...routeProps} />}
        />
        <Route
          path="/tags/:tag"
          exact
          render={routeProps => <PhotoFeed {...this.props} {...routeProps} />}
        />

        <Route
          path="/searchterm/:searchterm"
          exact
          render={routeProps => <PhotoFeed {...this.props} {...routeProps} />}
        />
        {this.props.isLoading ? (
          <Loading />
        ) : (
          <Route
            path="/single/:postId"
            render={routeProps => (
              <SinglePost {...this.props} {...routeProps} />
            )}
          />
        )}

        <Route
          path="/new"
          render={routeProps => <NewPost {...this.props} {...routeProps} />}
        />
        <Route
          path="/search"
          render={routeProps => <Search {...this.props} {...routeProps} />}
        />
      </Switch>
    );
  }
}

export default Router;
