import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useHistory } from "react-router";

import "./TagCloud.scss";
import { bindActionCreators } from "redux";
import { setPage } from "../../actions/actionCreators";

export const TagCloudComponent = ({ posts, max = 12, setPage }) => {
  const history = useHistory();
  const taglist = {};

  const addToTaglist = tag => {
    const cleanedTag = tag.replace("#", "");
    if (taglist[cleanedTag]) {
      taglist[cleanedTag] = taglist[cleanedTag] + 1;
    } else {
      taglist[cleanedTag] = 1;
    }
  };

  posts.forEach(post => {
    const tags = post.tags;
    tags.forEach(tag => {
      addToTaglist(tag);
    });
  });

  const sortedHiLoTags = Object.keys(taglist)
    .map(key => ({ name: key, count: taglist[key] }))
    .sort((a, b) => b.count - a.count);

  const goto = tag => {
    setPage(1);
    history.push(`tags/${tag}`);
  };

  return (
    <div className="tag-cloud">
      {sortedHiLoTags.slice(0, max).map(tag => (
        <div
          className="tag-cloud__tag"
          key={tag.name}
          onClick={() => {
            goto(tag.name);
          }}
        >
          #{tag.name}
        </div>
      ))}
    </div>
  );
};

// PropTypes for this Component
TagCloudComponent.propTypes = {
  posts: PropTypes.any,
  max: PropTypes.number,
  setPage: PropTypes.func
};

// Map Redux State To Props
const mapStateToProps = state => {
  return {
    posts: state.posts
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ setPage }, dispatch);
};

// Connect Props and Dispatch to Component
export const TagCloud = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagCloudComponent);
