import React, { Component } from 'react';

/* components */
import Router from './Router';
import Loading from './Loading';

/* styles */
import Footer from './Footer';

class AuthenticatedApp extends Component {
    render() {
        return (
            <React.Fragment>


                {this.props.isLoading ? <Loading /> : ''}

                <Router {...this.props} />

                <Footer {...this.props} />
            </React.Fragment>
        );
    }
}

export default AuthenticatedApp;


