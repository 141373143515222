import React, { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import "./Pagination.scss";
import { bindActionCreators } from "redux";
import { setPage } from "../../actions/actionCreators";

export const PaginationComponent = ({
  total,
  perPage,
  currentPage,
  setPage
}) => {
  const maxPage = Math.ceil(total / perPage);
  const [cp, setCP] = useState(1);

  const changePage = page => {
    setCP(page);
    setPage(page);
  };

  const goToPage = e => {
    if (e.target.value >= 1 && e.target.value <= maxPage) {
      changePage(e.target.value);
    } else if (e.target.value === "") {
      changePage(1);
    } else {
      console.log("ERROR - NO VALID INPUT");
    }
  };

  const prevPage = () => {
    if (currentPage > 1) changePage(currentPage - 1);
  };

  const nextPage = () => {
    if (currentPage < maxPage) changePage(currentPage + 1);
  };

  if (maxPage <= 1) return <div></div>;

  return (
    <div className="pagination">
      <div className="pagination__item" onClick={prevPage}>
        &lt;&lt;
      </div>
      <div className="pagination__pages">
        <div
          className={`pagination__item ${currentPage === 1 &&
            "pagination__item--active"}`}
          onClick={() => changePage(1)}
        >
          {1}
        </div>

        <div className="pagination__placeholder">...</div>
        <div className="pagination__input">
          <input
            type="number"
            placeholder={currentPage}
            value={cp}
            onFocus={() => {
              setCP(null);
            }}
            onChange={goToPage}
            onKeyUp={goToPage}
          />
        </div>
        <div className="pagination__placeholder">...</div>
        <div
          className={`pagination__item ${currentPage === maxPage &&
            "pagination__item--active"}`}
          onClick={() => changePage(maxPage)}
        >
          {maxPage}
        </div>
      </div>
      <div className="pagination__item" onClick={nextPage}>
        &gt;&gt;
      </div>
    </div>
  );
};

// PropTypes for this Component
PaginationComponent.propTypes = {
  perPage: PropTypes.number,
  currentPage: PropTypes.number,
  total: PropTypes.number,
  setPage: PropTypes.func
};

// Map DispatchToProps
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ setPage }, dispatch);
};

// Map Redux State To Props
const mapStateToProps = state => {
  return {
    perPage: state.application.perPage,
    currentPage: state.application.currentPage
  };
};

// Connect Props and Dispatch to Component
export const Pagination = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginationComponent);
