import React, { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import { setPage } from "../../actions/actionCreators";
import { TagCloud } from "../TagCloud/TagCloud";

import "./Search.scss";

export const Search = ({ setPage }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const changeSearchTerm = e => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (searchTerm !== "") {
      setPage(1);
      history.push(`/searchterm/${searchTerm}`);
    }
  };

  return (
    <div className="search">
      <TagCloud />
      <form className="search__form" onSubmit={handleSubmit}>
        <input
          placeholder="Suchbegriff"
          type="text"
          value={searchTerm}
          onChange={changeSearchTerm}
        />
        <button type="submit">Suchen</button>
      </form>
    </div>
  );
};

// PropTypes for this Component
Search.propTypes = {
  setPage: PropTypes.func
};

// Map Redux State To Props
const mapStateToProps = state => {
  return {};
};

// Map DispatchToProps
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ setPage }, dispatch);
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps, mapDispatchToProps)(Search);
