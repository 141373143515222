import { SET_LOADING, SET_NOT_LOADING } from '../actions/actionTypes';
import { IS_DEBUGGING } from '../config';

export const loadingReducer = (state = true, action) => {
  switch (action.type) {
    case SET_LOADING:
      if (IS_DEBUGGING) console.log('setLoading', action.isLoading);
      return action.isLoading;
    case SET_NOT_LOADING:
      if (IS_DEBUGGING) console.log('setNotLoading', action.isLoading);
      return action.isLoading;
    default:
      return state;
  }
};
