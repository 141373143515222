import { SET_PAGE } from "../actions/actionTypes";

const initialState = {
  currentPage: 1,
  perPage: 10,
  searchTerm: ""
};

export const application = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE: {
      return {
        ...state,
        currentPage: action.page
      };
    }
    default:
      return state;
  }
};
