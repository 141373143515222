export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const UPLOAD_FOLDER = process.env.REACT_APP_UPLOAD_FOLDER;
export const UPLOAD_SKRIPT = process.env.REACT_APP_UPLOAD_SKRIPT;

export const MAX_IMAGE_WIDTH = 600;

export const API = {
  getPosts: process.env.REACT_APP_GET_POSTS_API,
  addPost: process.env.REACT_APP_ADD_POST_API,
  updatePost: process.env.REACT_APP_UPDATE_POST_API,
  removePost: process.env.REACT_APP_REMOVE_POST_API,
  auth: process.env.REACT_APP_AUTH_API
};

export const IS_DEBUGGING = process.env.REACT_APP_IS_DEBUG === "true";
