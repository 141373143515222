import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HASHTAG_REGEX, getTagsFromText } from "../helper";
import PhotoSlider from "./PhotoSlider";
import { IS_DEBUGGING } from "../config";

class Photo extends Component {
  savePost = () => {
    const dateArray = this.refs.date.value.split(".");
    const date = Date.parse(`${dateArray[1]} ${dateArray[0]} ${dateArray[2]}`);
    const headline = this.refs.headline.value;
    const text = this.refs.text.value;
    const tags = getTagsFromText(text);
    const isPublic = false;
    const postId = this.props.match.params.postId;

    const postData = {
      postId,
      headline,
      text,
      tags,
      date,
      isPublic
    };

    this.props.updatePost(postData).then(() => {
      this.props.cancelEdit();
    });
  };

  parseText = text => {
    const splitText = text.split(HASHTAG_REGEX);

    if (splitText.length <= 1) {
      return text;
    }

    const matches = text.match(HASHTAG_REGEX);

    return splitText.reduce(
      (arr, element, index) =>
        matches[index]
          ? [
              ...arr,
              element,
              <Link
                key={index}
                to={`/tags/${matches[index].toLowerCase().replace("#", "")}`}
              >
                {matches[index]}
              </Link>
            ]
          : [...arr, element],
      []
    );
  };

  render() {
    if (IS_DEBUGGING) console.log("Photo", this.props);
    const { post, isEditable } = this.props;
    const date = new Date(Number.parseInt(post.date));
    return (
      <div className="photo">
        <figure>
          <Link className="photo__image" to={`/single/${post.postId}`}>
            <PhotoSlider images={post.imageSrc} alt={post.headline} />
          </Link>

          {isEditable ? (
            <figcaption className="edit">
              <input
                type="text"
                ref="date"
                defaultValue={date.toLocaleDateString()}
              />
              <input type="text" ref="headline" defaultValue={post.headline} />
              <textarea ref="text" defaultValue={post.text} />
              <button onClick={this.savePost}>speichern</button>
            </figcaption>
          ) : (
            <figcaption className="nonEdit">
              <span className="photo__date">{date.toLocaleDateString()}</span>
              <Link to={`/single/${post.postId}`}>
                <h2>{post.headline}</h2>
              </Link>
              {post.text.split(/\r?\n/).map(text => (
                <p>{this.parseText(text)}</p>
              ))}
            </figcaption>
          )}
        </figure>
      </div>
    );
  }
}

export default Photo;
