import React, { Component } from "react";
import { UPLOAD_FOLDER, IS_DEBUGGING } from "../config";

import Slider from "react-slick";

// TODO RENAME TO MEDIA SLIDER
class PhotoSlider extends Component {
  playVideo = event => {
    if (event.target.paused) {
      event.target.play();
    } else {
      event.target.pause();
    }
  };

  render() {
    if (IS_DEBUGGING) console.log(this.props.images);
    const sliderSettings = {
      dots: true,
      infinite: true,
      lazyLoad: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    };
    return (
      <div>
        <Slider {...sliderSettings}>
          {this.props.images
            .sort((a, b) => {
              if (a.toLowerCase().substr(1) > b.toLowerCase().substr(1)) {
                return 1;
              }
              if (a.toLowerCase().substr(1) < b.toLowerCase().substr(1)) {
                return -1;
              }
              return 0;
            })
            .map((image, index) => {
              // TODO Create own components for video and images
              if (image.indexOf("jpg") !== -1) {
                return (
                  <img
                    key={index}
                    src={`${UPLOAD_FOLDER}${image}`}
                    alt={this.props.alt}
                  />
                );
              }
              if (image.indexOf("mp4") !== -1) {
                return (
                  <video key={index} onClick={this.playVideo} controls>
                    <source src={`${UPLOAD_FOLDER}${image}`} />
                  </video>
                );
              }
              return "";
            })}
        </Slider>
      </div>
    );
  }
}

export default PhotoSlider;
