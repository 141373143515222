// dependencies
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";

// reducer
import { rootReducer } from "./reducers/index";
import { IS_DEBUGGING } from "./config";

const defaultState = {
  posts: []
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  defaultState,
  composeEnhancers(applyMiddleware(reduxThunk))
);

if (IS_DEBUGGING) console.log("init storeState", store.getState());

export default store;
