import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import { postsReducer as posts } from "./postsReducer";
import { loadingReducer as isLoading } from "./loadingReducers";
import { authReducer as auth } from "./authReducers";
import { application } from "./application";

export const rootReducer = combineReducers({
  posts,
  isLoading,
  auth,
  application,
  routing: routerReducer
});

export default rootReducer;
