/* components */
import App from "./App";

/* redux */
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actionCreators from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    posts: state.posts,
    tags: state.tags,
    isLoading: state.isLoading,
    auth: state.auth,
    application: state.application
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(actionCreators, dispatch);
};

// connect state and -actions- to a component
const AppWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(App)
);

export default AppWrapper;
