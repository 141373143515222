import {
  GET_POSTS,
  ADD_POST,
  SET_LOADING,
  SET_NOT_LOADING,
  UPDATE_POST,
  REMOVE_POST,
  SET_AUTH,
  SET_PAGE
} from "./actionTypes";
import { hlpr_fetchData } from "../../node_modules/hlpr/hlpr";
import { API } from "../config";
import {
  transformPostForStore,
  transformPostsForStore,
  transformPostForDatabase
} from "../helper";

export const addPost = (postObject, history) => async dispatch => {
  return hlpr_fetchData(API.addPost, transformPostForDatabase(postObject), {
    credentials: "include"
  }).then(response => {
    dispatch({
      type: ADD_POST,
      post: transformPostForStore(response)
    });
    dispatch(setNotLoading());
    history.push(`/single/${postObject.postId}`);
  });
};

export const updatePost = postObject => async dispatch => {
  return hlpr_fetchData(API.updatePost, transformPostForDatabase(postObject), {
    credentials: "include"
  }).then(response => {
    dispatch({
      type: UPDATE_POST,
      post: transformPostForStore(response)
    });
    dispatch(setNotLoading());
  });
};

export const removePost = (postId, history) => async dispatch => {
  return hlpr_fetchData(
    API.removePost,
    { postId },
    { credentials: "include" }
  ).then(response => {
    history.push(`/`);
    dispatch({
      type: REMOVE_POST,
      postId: response
    });
  });
};

export const getPosts = () => async dispatch => {
  dispatch(setLoading());

  return fetch(API.getPosts, { credentials: "include" })
    .then(response => response.json())
    .then(posts => {
      dispatch({
        type: GET_POSTS,
        posts: transformPostsForStore(posts)
      });
      dispatch(setNotLoading());
    });
};

export const setLoading = () => {
  return {
    type: SET_LOADING,
    isLoading: true
  };
};

export const setNotLoading = () => {
  return {
    type: SET_NOT_LOADING,
    isLoading: false
  };
};

export const setAuth = password => async dispatch => {
  dispatch({
    type: SET_AUTH,
    auth: "NOT_SET"
  });
  return hlpr_fetchData(
    API.auth,
    { password },
    { credentials: "include" }
  ).then(response => {
    dispatch({
      type: SET_AUTH,
      auth: response.auth
    });
  });
};

export const setPage = page => async dispatch => {
  dispatch({
    type: SET_PAGE,
    page: page
  });
};
