import React, { Component } from "react";
import {
  createId,
  uploadFiles,
  getTagsFromText,
  handleFiles,
  isImage
} from "../helper";

class NewPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      filesObj: {}
    };

    this.mediaRef = React.createRef();
    this.headlineRef = React.createRef();
    this.textRef = React.createRef();
    this.formRef = React.createRef();

    this.date = Date.now();
  }

  getCurrentPostFromPosts = (posts, postId) => {
    const post = posts.filter(post => post.postId === postId);
    return post[0];
  };

  handleSubmit = event => {
    event.preventDefault();
    // COLLECT DATA
    const correctedHeadline = this.headlineRef.current.value.replace(" ", "_");
    const filesObj = { ...this.state.filesObj };
    const formData = new FormData();
    const postId = createId();
    const headline = this.headlineRef.current.value;
    const text = this.textRef.current.value;
    const tags = getTagsFromText(text);
    const date = this.date;
    const isPublic = false;

    this.props.setLoading();

    // PREPARE FILES
    const resizedImagesPromises = handleFiles(
      this.mediaRef.current.files,
      `${correctedHeadline}_${this.date}`
    );
    let xhrPromises = [];
    Promise.all(resizedImagesPromises).then(objects => {
      objects.forEach((object, i) => {
        const normalizedFilename = object.fileName
          .replace(/[^a-z0-9]/gi, "_")
          .toLowerCase();
        const fileName = `${filesObj[object.key].order}_${normalizedFilename}`;
        const file = new File([object.blob], fileName);
        formData.set("media", file);
        xhrPromises.push(uploadFiles(formData));
      });

      // UPLOAD FILES // ADD POST
      Promise.all(xhrPromises).then(responses => {
        const imageSrc = responses;
        const postData = {
          postId,
          headline,
          text,
          tags,
          date,
          imageSrc,
          isPublic
        };
        this.props.addPost(postData, this.props.history);
      });
    });
  };

  addPreviewAndOrder = () => {
    const filesObj = { ...this.mediaRef.current.files };
    Object.keys(filesObj).forEach(key => {
      filesObj[key].url = URL.createObjectURL(filesObj[key]);
      filesObj[key].order = key;
    });

    this.setState({ filesObj });
  };

  changeOrder = event => {
    const filesObj = { ...this.state.filesObj };
    const key = event.target.dataset.key;
    const order = event.target.value;

    filesObj[key].order = order;

    this.setState({ filesObj });
  };

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="newPostForm"
        ref={this.formRef}
      >
        <input
          type="file"
          ref={this.mediaRef}
          name="media"
          multiple
          accept=".jpg,.png,.gif,.bmp,.mp4,"
          required
          onChange={this.addPreviewAndOrder}
        />
        <div className="imageContainer" ref="container">
          {Object.keys(this.state.filesObj).map(key => {
            const file = this.state.filesObj[key];

            // TODO Refactor into own compoenent (?)
            if (isImage(file.type)) {
              return (
                <span key={key} className="imageContainer__content">
                  <input
                    type="number"
                    defaultValue={key}
                    onChange={this.changeOrder}
                    data-key={key}
                  />
                  <img src={file.url} alt={file.name} />
                </span>
              );
            }
            return "";
          })}
        </div>
        <input
          type="text"
          ref={this.headlineRef}
          placeholder="Überschrift"
          required
        />
        <textarea ref={this.textRef} placeholder="Beschreibung" />

        <button type="submit">Abschicken</button>
      </form>
    );
  }
}

export default NewPost;
